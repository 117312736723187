import { auth } from '../req'
const baseURL = 'manager/manager/'


async function list(pageSize, currentPage) {
	return await auth.post(baseURL + 'list',
		{ pageSize, currentPage }
	)
}

async function modifyAccess(id, access) {
	return await auth.post(baseURL + 'modifyAccess',
		{ id, access }
	)
}

async function create(phoneNumber, password, access, nickname) {
	return await auth.post(baseURL + 'create',
		{ phoneNumber, password, access, nickname }
	)
}
async function info() {
	return await auth.get(baseURL + 'info')
}


async function modifyPassword(password) {
	return await auth.post(baseURL + 'modifyPassword',
		{ password }
	)
}


export default {
	list,
	modifyAccess,
	create,
	info,
	modifyPassword,
}
