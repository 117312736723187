let _token = null
let _has = false

function readFromLocal() {
	return sessionStorage.getItem('token')
}
function writeToLocal(token) {
	if (token == undefined) return;
	sessionStorage.setItem('token', token)
}

function removeFromLocal() {
	sessionStorage.removeItem('token')
}


export function has() {
	return _has
}

export function get() {
	return _token
}

export function set(token) {
	_token = token
	_has = token != undefined
	writeToLocal(token)
}

export function remove() {
	_token = null
	_has = false
	removeFromLocal()
}


set(readFromLocal())


export default {
	has,
	get,
	set,
	remove
}