import Vue from 'vue'
import VueRouter from 'vue-router'
import Console from '../views/Console.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		redirect: '/Console'
	},
	{
		path: '/Console',
		name: 'Console',
		component: Console,
		children: [
			{
				path: '/',
				redirect: 'OrderChecking'
			},
			{
				path: 'OrderChecking',
				name: 'OrderChecking',
				component: () => import('../views/console/CheckingConsole.vue'),
				meta: {
					title: '订单审核'
				}
			},
			{
				path: 'OrderPostReceiving',
				name: 'OrderPostReceiving',
				component: () => import('../views/console/PostReceivingConsole.vue'),
				meta: {
					title: '订单用户物料接收'
				}
			},
			{
				path: 'ManagerList',
				name: 'ManagerList',
				component: () => import('../views/console/ManagerList.vue'),
				meta: {
					title: '人员列表'
				}
			},
			{
				path: 'CreateManager',
				name: 'CreateManager',
				component: () => import('../views/console/CreateManager.vue'),
				meta: {
					title: '创建人员'
				}
			},
			{
				path: 'ManagerInfo',
				name: 'ManagerInfo',
				component: () => import('../views/console/ManagerInfo.vue'),
				meta: {
					title: '我的信息'
				}
			},
			{
				path: 'ManagerModifyPassword',
				name: 'ManagerModifyPassword',
				component: () => import('../views/console/ManagerModifyPassword.vue'),
				meta: {
					title: '修改密码'
				}
			},
			{
				path: 'NotifySettings',
				name: 'NotifySettings',
				component: () => import('../views/console/NotifySettings.vue'),
				meta: {
					title: '通知设置'
				}
			},
		]
	},
	{
		path: '/signin',
		name: 'Signin',
		component: () => import('../views/Signin.vue'),
		meta: {
			title: '管理员登录'
		}
	},
]

const router = new VueRouter({
	routes
})

export default router
