import { auth } from '../req'
const baseURL = 'manager/order/'


async function getChekingList(pageSize, currentPage, filter) {
	return await auth.post(baseURL + 'getChekingList',
		{ pageSize, currentPage, filter}
	)
}
async function getPostReceivingList(pageSize, currentPage, filter) {
	return await auth.post(baseURL + 'getPostReceivingList',
		{ pageSize, currentPage, filter }
	)
}

async function getForChecking(no) {
	return await auth.post(baseURL + 'getForChecking',
		{ no }
	)
}

async function getDocumentDownloadUrl(no, type) {
	return await auth.post(baseURL + 'getDocumentDownloadUrl',
		{ no, type }
	)
}

async function checked(no) {
	return await auth.post(baseURL + 'checked',
		{ no }
	)
}

async function checkFailed(no, reason) {
	return await auth.post(baseURL + 'checkFailed',
		{ no, reason }
	)
}

async function receivedUserPost(no) {
	return await auth.post(baseURL + 'receivedUserPost',
		{ no }
	)
}



export default {
	getChekingList,
	getPostReceivingList,
	getDocumentDownloadUrl,
	getForChecking,
	checked,
	checkFailed,
	receivedUserPost,
}