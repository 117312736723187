import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'element-ui/lib/theme-chalk/index.css'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import relativeTime from "dayjs/plugin/relativeTime"
import api from './api'

Vue.config.productionTip = false

Vue.prototype.$day = dayjs
dayjs.locale('zh-cn')
dayjs.extend(relativeTime)


Vue.prototype.$api = api

router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
