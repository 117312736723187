<template>
	<div style="height: 100%">
		<el-container style="height: 100%">
			<el-aside width="200px">
				<el-menu :default-active="menuActive" class="el-menu-vertical-demo" router>
					<el-submenu index="1">
						<template slot="title">
							<i class="el-icon-location"></i>
							<span>订单管理</span>
						</template>
						<el-menu-item index="/Console/OrderChecking"
							><i class="el-icon-folder-checked"></i>审核订单</el-menu-item
						>
						<el-menu-item index="/Console/OrderPostReceiving"
							><i class="el-icon-collection"></i>物料收货</el-menu-item
						>
						<el-menu-item index="1-3"
							><i class="el-icon-video-play"></i>受理订单</el-menu-item
						>
						<el-menu-item index="1-4"
							><i class="el-icon-c-scale-to-original"></i>导板设计</el-menu-item
						>
						<el-menu-item index="1-5"
							><i class="el-icon-printer"></i>导板制作</el-menu-item
						>
						<el-menu-item index="1-6"
							><i class="el-icon-box"></i>产品配货</el-menu-item
						>
						<el-menu-item index="1-7"
							><i class="el-icon-position"></i>产品发货</el-menu-item
						>
					</el-submenu>
					<el-submenu index="2">
						<template slot="title">
							<i class="el-icon-user-solid"></i>
							<span slot="title">人员管理</span>
						</template>
						<el-menu-item index="/Console/ManagerList"
							><i class="el-icon-tickets"></i>人员列表</el-menu-item
						>
						<el-menu-item index="/Console/CreateManager"
							><i class="el-icon-folder-add"></i>创建人员</el-menu-item
						>
					</el-submenu>
					<el-submenu index="3">
						<template slot="title">
							<i class="el-icon-s-opportunity"></i>
							<span slot="title">关于我</span>
						</template>
						<el-menu-item index="/Console/ManagerInfo"
							><i class="el-icon-set-up"></i>我的信息</el-menu-item
						>
						<el-menu-item index="/Console/ManagerModifyPassword"
							><i class="el-icon-lock"></i>修改密码</el-menu-item
						>
					</el-submenu>
					
					
					<el-menu-item index="/Console/NotifySettings"
						><i class="el-icon-bell"></i>通知设置</el-menu-item
					>
				</el-menu>
			</el-aside>
			<el-container>
				<el-header height="56px" style="padding-top: 15px"
					><el-page-header @back="() => $router.go(-1)" :content="currentPageTitle"></el-page-header
				></el-header>
				<el-main>
					<router-view />
				</el-main>
				<!-- <el-footer></el-footer> -->
			</el-container>
		</el-container>
	</div>
</template>

<script>
import {
	Container,
	Aside,
	Main,
	Header,
	Menu,
	MenuItem,
	Submenu,
	PageHeader,
	Message,
	MessageBox
} from "element-ui";

import tokenManager from '../tokenManager'
import listener from '../listener'


export default {
	name: "Console",
	components: {
		ElContainer: Container,
		ElAside: Aside,
		ElMain: Main,
		ElHeader: Header,
		ElMenu: Menu,
		ElMenuItem: MenuItem,
		ElSubmenu: Submenu,
		ElPageHeader: PageHeader
	},
	data(){
		return {
			menuActive: '',
			currentPageTitle: ''
		}
	},
	created(){
		this.menuActive = this.$route.path
		this.currentPageTitle = this.$route.meta.title
		
	},
	async mounted(){
		if(!tokenManager.has()){
			this.$router.push({ name: "Signin" });
			return;
		}
		try{
			const { data } = await this.$api.manager.manager.info()
			if(data.code !== 'OK'){
				this.$router.push({ name: "Signin" });
			}
		} catch(e){
			Message.error('错误: ' + e)
			return;
		}

		const {audio} = listener.getSettings()
		if(audio){
			MessageBox.alert('您当前已开启语音播报，由于浏览器策略限制，为确保正常播报，需要您点击一下下方的确定按钮！', '提示')
		}

		listener.start();
	},
	beforeDestroy(){
		listener.stop();
	},
	watch: {
		$route(to, /*from*/) {
			this.menuActive = to.path
			this.currentPageTitle = to.meta.title
		},
	}
};
</script>

<style>
.el-header {
	box-sizing: border-box;
}
.el-footer {
	/* background-color: #b3c0d1;
	color: #333;
	text-align: center;
	line-height: 60px; */
}

.el-main {
	background-color: #e9eef3;
}

body > .el-container {
}
</style>