
import tokenManager from './tokenManager'
import { io } from "socket.io-client"

import config from './config'
import newOrderNotifyMp3 from '../public/newOrderNotify.mp3'
import { Notification as ElNotification, Message  } from 'element-ui';

class NotifyAudio {
	constructor(src) {
		const audio = new Audio(src)
		audio.muted = true
		this.audio = audio
		this._auth = false
		document.body.addEventListener('mousedown', function () {
			if (!this._auth){
				audio.play()
				this._auth = true
			}
		}, false);
	}

	play() {
		this.audio.currentTime = 0
		this.audio.muted = false
		this.audio.play()
	}
}

let newOrderNotifyAudio = new NotifyAudio(newOrderNotifyMp3)
let socket = null
let _settings = {
	browser: true,
	desktop: false,
	audio: false
}

let _lastWebNotification = null
let _started = false


export const desktoPNotifySupported = 'Notification' in window


function readFromLocal(){
	let localeData = localStorage.getItem('NotifySettings')
	if (localeData){
		try{
			localeData = JSON.parse(localeData)
		} catch(e){
			return;
		}
		setSettings(localeData)
	}
}

function writeToLocal(){
	const localeData = JSON.stringify(_settings)
	localStorage.setItem('NotifySettings', localeData)
}

function realStart(){

	if (socket != null) {
		socket.close()
	}

	socket = io(config.SOCKET_URI, {
		auth: {
			token: tokenManager.get()
		}
	})

	socket.on('connect_error', (err) => {
		Message.error('订单消息订阅：连接失败, 请刷新或重新登录！(' + err.message + ')')
	})

	socket.on('error', (err) => {
		Message.error('订单消息订阅：连接断开, 请刷新或重新登录！(' + err.message + ')')
	})

	socket.on('newOrder', () => {
		if (_settings.audio) {
			newOrderNotifyAudio.play()
		}

		if (_settings.browser) {
			ElNotification({
				title: '消息',
				message: '有新的订单提交，请及时审核！',
				position: 'bottom-right'
			})
		}

		if (_settings.desktop) {
			if (_lastWebNotification) {

				_lastWebNotification.close()
			}
			_lastWebNotification = new Notification('活动翼个性化定制平台-管理后台', {
				body: '有新的订单提交，请及时审核！'
			})
		}
	})
}

function realStop(){
	if (socket){
		socket.close()
		socket = null
	}
}

function isNeedListen(){
	return _settings.browser || _settings.desktop || _settings.audio
}

export function start() {
	_started = true

	if (isNeedListen()) {
		realStart()
	}
}

export function stop() {
	_started = false
	realStop()
}



export function setSettings(settings) {
	if (settings.browser != undefined){
		_settings.browser = !!settings.browser
	}

	if (settings.desktop != undefined) {
		let desktop = !!settings.desktop

		if (!desktoPNotifySupported){
			desktop = false
		}

		if (_settings.desktop !== desktop){
			if (desktop) {
				Notification.requestPermission(function (/*PERMISSION*/) {
					// if (PERMISSION === 'granted') {
					// }
				});

			}
		}

		_settings.desktop = !!settings.desktop
		
	}

	if (settings.audio != undefined) {
		_settings.audio = !!settings.audio
	}

	writeToLocal()

	if (_started){
		const needListen_ = isNeedListen()

		if (needListen_ && socket == null ){
			realStart()
		}


		if (!needListen_){
			realStop()
		}

	}
}

export function getSettings() {
	return _settings
}



readFromLocal()

export default {
	desktoPNotifySupported,
	start,
	stop,
	setSettings,
	getSettings
}