import axios from 'axios'
import config from '../config'
import tokenManager from '../tokenManager'

const guestReq = axios.create({
	baseURL: config.API_GETAWAY
})

guestReq.interceptors.request.use(function (config) {
	//config.headers['Client-Ver'] = app.CLIENT_VER
	return config
})

const authReq = axios.create({
	baseURL: config.API_GETAWAY
})
authReq.interceptors.request.use(function (config) {
	config.headers.Authorization = tokenManager.get()
	//config.headers['Client-Ver'] = app.CLIENT_VER
	return config
})

export const guest = guestReq
export const auth = authReq

export default {
	guest: guestReq,
	auth: authReq
}