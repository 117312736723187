import { guest } from '../req'
const baseURL = 'manager/session/'

async function signin(phoneNumber, password) {
	return await guest.post(baseURL + 'signin',
		{ phoneNumber, password }
	)
}

export default {
	signin,
}